.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 0.8;   
}
.kitsSection .carousel .control-next.control-arrow:before {
    border-left: 8px solid #2E3395;
}
.kitsSection .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #2E3395;
}

/* .carousel .thumbs-wrapper .control-next.control-arrow:before {
    border-left: 8px solid #2E3395;
}
.carousel .thumbs-wrapper .control-prev.control-arrow:before {
    border-right: 8px solid #2E3395;
} */

.carousel .thumbs-wrapper {
    margin: 0px;
}

.carousel .thumbs {
    padding: 0px;
}

.carousel .thumb {
    border-radius: 4px;
    /* it still zooms in a bit, idk how to get rid of this */
    transition: none; 
}

.carousel .thumb:hover {
    border: 3px solid #446AD5;
}

.carousel .thumb.selected, .carousel .thumb.selected:hover {
    border: 3px solid #2E3395;
}

.carousel .control-dots .dot {
    transition: none;
    box-shadow: none;
}
.kitsSection .carousel .control-dots .dot {
    /* border: 2px solid grey; */
    background-color: #2E3395;
}

.carousel {
    border-radius: 4px;
}

.carousel ul {
    margin-bottom: 0px;
}

.kitsSection .carousel .thumbs-wrapper .control-arrow {
    /* hack, since with adding the margin-bottom above it's no longer positioned propoerly */
    top: 49px;
}
.rendersSection .carousel .thumbs-wrapper .control-arrow {
    /* hack, since with adding the margin-bottom above it's no longer positioned propoerly */
    top: 42px;
}

/* .carousel:hover .slide .legend {
    opacity: 0.9;
} */

.carousel .slide .legend {
    transition: none;
    /* otherwise .carousel:hover .slide .legend applies */
    opacity: 1;
    bottom: 0px;
    right: 0px;
    left: unset;
    width: fit-content;
    height: fit-content; 
    font-size: 17px;
    border-radius: 0px 4px 0px 4px;
    padding: 4px 8px 4px 8px;
    /* background-color: rgba(255,255,255,0.2); */
    background-color: unset;
    font-family: Menlo, Consolas, monospace;
    font-weight: 600;

    /* position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center; */
}

@media (max-width:800px) {
    .carousel .slide .legend {
        top: 0px;
        font-size: 12px;
        line-height: 16px;
    }
}