.layoutRendererContainer {
    position: relative;
}

/* TODO: the way this css is set up doesn't make sense with separate keyRenderer, probably */
.keycap {
    position: absolute;
    box-sizing: border-box;
    padding: 2px;
}

.keycapInner {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 7px;
}

/* .keycapBezel {
    float: left;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-left: 20px solid rgba(255, 255, 255, 0.2);
    border-top: 20px solid rgba(255, 255, 255, 0.2);
    border-right: 20px solid rgba(255, 255, 255, 0.2);
    border-bottom: 20px solid rgba(255, 255, 255, 0.2);
} */

.keycapTop {
    /* position: absolute;
    top: 0px;
    left: 0px; */
    border-radius: 3px;
    width: 100%;
    height: 100%;
}

.keycapMask {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
}

.keycapLabel {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 12px;
    line-height: 12px;
    font-family: Consolas, monospace;
    width: fit-content;
    height: fit-content;
    /* these styles are for the puppeteer export */
    font-weight: bold;
    font-size: 10px;
}

.keycapHomingBar {
    position: absolute;
    left: 18px;
    bottom: 11px;
    height: 4px;
    width: 16px;
    border-radius: 2px;
}

.keycapHomingScoop {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    /* hack: assuming all homing keycaps are the same colour */
    background-color: #F5F5F5;
    /* background-color: #eeeeee; */
}