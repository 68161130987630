.mr-half {
    margin-right: 4px;
}
.mr1 {
    margin-right: 8px;
}
.mr2 {
    margin-right: 16px;
}
.mb1 {
    margin-bottom: 8px;
}
.mb2 {
    margin-bottom: 16px;
}
.mt1 {
    margin-top: 8px;
}
.mt2 {
    margin-top: 16px;
}

.greyWrapper {
    background-color: #eeeeee;
    border-radius: 4px;
    padding: 16px;
}
