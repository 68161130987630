html {
    scroll-behavior: smooth;
  }

body {
    font-family: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
    background-color: #2E3395;
    color: rgb(69, 69, 73);
    font-size: 17px;
}

/* hack: scrolling to top of page doesn't include header margin otherwise */
#topWrapper {
    height: 1px;
    position: absolute;
    top: -1px; 
}

#main {
    max-width: 1280px;
    background-color: white;
    margin: 24px auto 24px auto;
    border-radius: 8px;
}

#navSection {
    width: 100%;
    box-sizing: border-box;
    /* this is to make the rounded corner "work" for the stickiness */
    /* TODO: add stickiness back? */
    background-color: #2E3395;
    /* position: sticky; */
    /* hack: not sure why this is needed guh */
    /* top: -2px; */
    font-size: 17px;
    /* font-weight: bold; */
}

#navSectionInner {
    width: 100%;
    background-color: #FBDB8C;
    padding: 8px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 8px 8px 0px 0px;
}

#navSection a, a:visited {
    color: #C6915E;
    font-weight: bold;
    /* text-decoration-color: #dfac69; */
}

#navSection a:hover {
    background-color: #F2CE83;
}

#navSection a:focus-visible {
    outline: white auto;
}

.navLink {
    margin-left: 6px;
    margin-right: 6px; 
    border-radius: 4px;
    padding: 4px;
    /* to prevent links from being broken across lines */
    display: inline-block; 
}

.bigHeader {
    font-family: 'Petit Formal Script';
    text-align: center;
    color: white;
    margin: 32px;
}

.innerHeader {
    font-family: 'Petit Formal Script';
    color: #2E3395;
    /* border-bottom: 2px solid #446AD5; */
    margin-bottom: 16px;
    line-height: 30px;
}

.innerSection {
    margin: 32px;
    margin-top: 24px;
    line-height: 26px;
    /* no idea why this is necessary */
    padding-bottom: 32px;
}

.halfAreaWrapper {
    /* not currently used? */
    vertical-align: top;
}

.subArea {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
}

.subAreaPaddingRight {
    padding-right: 16px;
}

.subAreaPaddingLeft {
    padding-left: 16px;
}

/* Regrets */
.subAreaPaddingRightPartial {
    padding-right: 20px;
}

.subAreaPaddingLeftPartial {
    padding-left: 20px;
}

.subAreaPaddingMiddle { 
    padding-right: 10px;
    padding-left: 10px;
}

.halfArea {
    width: 50%;
}

.thirdArea {
    width: 33.33333%;
    /* box-sizing: content-box; */
}

.fourthArea {
    width: 25%;
}

@media (max-width:700px) {
    .halfArea {
        width: 100%;
    }
    .thirdArea {
        width: 100%;
    }
    .fourthArea {
        width: 25%;
    }
    .subAreaPaddingRight {
        padding-right: 0px;
    }
    .subAreaPaddingLeft {
        padding-top: 24px;
        padding-left: 0px;
    }
    .subAreaPaddingRightPartial {
        padding-right: 0px;
    }
    .subAreaPaddingMiddle {
        padding-top: 24px;
        padding-right: 0px;
        padding-left: 0px;
    }
    .subAreaPaddingLeftPartial {
        padding-top: 24px;
        padding-left: 0px;
    }
}

.emphasis {
    /* font-style: italic; */
    font-weight: bold;
    /* font-size: 17px; */
}

p {
    margin: 0px;
}

.innerSection a, a:visited {
    color: #2D3DB1;
    /* text-decoration-color: #dfac69; */
}

.innerSection a:hover {
    color: #446AD5;
}

.innerSection a:focus-visible {
    outline: #446AD5 auto;
}

.keycapWrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.divider {
    margin: 16px 0px 16px 0px;
    border-top: 2px dashed #eeeeee;
}

.kitThumbnail {
    /* border-radius: 4px; */
    width: 100%;
    height: auto;
}

.otherCollabImage {
    border-radius: 4px;
    width: 100%;
    height: auto;
}

.otherCollabBox {
    text-align: center;
}

.boxLink {
    font-size: 22px;
    text-align: center;
    padding: 16px;
    /* border-radius: 4px;
    border: 2px dashed #2E3395; */
}

.boxLinkSubtitle {
    font-style: italic;
}

.vendorList {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: auto;
}

@media (max-width:700px) {
    .vendorList {
        display: block;
    }
}
.vendor {
    padding: 4px;
    display: inline-block;
}
.region {
    font-weight: bold;
}

.collaborator {
    width: 302px;
    display: inline-block;
    text-align: center;
}

.update {
    width: 100%;
    display: flex;
}

.updateDate {
    font-weight: bold;
    width: 120px;
    flex: none;
}

.updateContent {
    width: auto;
}

@media (max-width:700px) {
    .update {
        display: block;
    }
    .updateDate {
        width: 100%;
    }
}

.creditsList {
    padding-left: 24px;
    margin: 0px;
}

#topOfPage {
    text-align: right;
}

#madeWithCookies {
    text-align: center;
    color: white;
    margin-bottom: 32px;
}

#madeWithCookiesInner {
    display: flex;
    align-items: center;
    justify-content: center;
}

#footerContact {
    font-size: 14px;
}